import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import '../css/App.css';
import Sns from './Sns';


class AboutEn extends React.Component {
    render() {
        return (
            <Grid
                container
                direction="column"
                justify="center">
                     <div className="App">
          <Sns />
        </div>
                <div class="box">
                    
                    <div class="contents">
                        <div class="lang">
                            Language: <Link to="/About">[Ja]</Link>
                        </div>
                        <br />

                        <h2>Profile</h2>
                        <p>Toriyama Namiko (Mis)</p>
                        <p>E-mail: namiko.trym@gmail.com</p>

                        <h2>Education</h2>
                        <p>April 2023 - Present</p>
                        <p class="indent">Graduate School of Humanities and Sciences (Doctor degree) , Ochanomizu University - Tokyo, Japan</p>
                        <p>April 2021 - March 2023</p>
                        <p class="indent">Graduate School of Humanities and Sciences (Master degree) , Ochanomizu University - Tokyo, Japan</p>
                        <p>February 2019 - March 2019 </p>
                        <p class="indent">English Language Short Courses, University of Hull - Hull, United Kingdom </p>
                        <p>April 2017 - March 2021 </p>
                        <p class="indent">Department of Information Sciences, Ochanomizu University - Tokyo, Japan</p>

                        <h2>Work Experiences</h2>
                        <p>April 2023 - Present Backend Engineer Mercari, Inc. </p>
                        ・Work at Customer Experience team<br />
                        <br />
                        <p>March 2020 - Present Research Engineer Weather Map Co., Ltd. Tokyo </p>
                        ・Research on how to improve typhoon label placement.<br />
                        ・Using Python to mathematically improve label placement from typhoon information in SVG format.<br />
                        <br />
                        <p>October 2021 - March 2022 Backend Engineer Mercari, Inc. </p>
                        ・Data migration using Go and php.<br />
                        ・Worked as team leader in user domain service.<br />
                        <br />
                        <p>July 2021 - September 2021 Solutions Architect Amazon Web Services, Inc. Tokyo </p>
                        ・Worked at Prototyping team.<br />
                        ・Wrote article about the application using AWS. [<a href="https://aws.amazon.com/jp/builders-flash/202111/gluoncv-lambda-for-telework/?awsf.filter-name=*all">link</a>]<br />
                        ・Joined AWS Women's Well-Architected hackason. <br />
                        <br />
                        <p>August 2020 - November 2020 Backend Engineer Mercari, Inc. </p>
                        ・Designed and implemented brand new gRPC endpoints in an existing microservice.<br />
                        ・Wrote unit, integration, e2e, and security tests<br />
                        ・Developed a service to issue widget codes and won a silver award in a hackathon in the company. I developed a simple microservice from scratch. <br />
                        ・Researched into different deployment solutions for an existing microservice to decrease latency, drafted meeting documents, and discussed with architects and other teams on what should be done<br />
                        ・Worked on a micro-service from design phase to release.<br />
                        <br />
                        <p>July 2018 - August 2018 Google STEP internship Google Japan G.K. </p>
                        ・Worked on Chrome infrastructure team.<br />
                        ・Developed a tool that put build time logs into BigQuery and analyzed the data.<br />
                        <br />
                        <p>June 2017 - October 2020 Engineer Yoshidumi Information Co., Ltd. </p>
                        ・Developed a search application using GCP<br />
                        ・Developed a web application for a business partner.<br />

                        <br></br>
                        <h2>Other Activity</h2>
                        <p>TEAra: <a href="https://note.com/lnamikol/n/na4da29fb373d">https://note.com/lnamikol/n/na4da29fb373d</a></p>

                        <h2>Presentation</h2>
                        <p>Namiko Toriyama、Tsubasa Kohyama、Hiroaki Miura "Spatio-Temporal Analysis of Typhoon Eye Diameters", AGU, December 2022</p>
                        <p>Namiko Toriyama、Tsubasa Kohyama、Hiroaki Miura "Analysis of Typhoon-Eye Diameters in Satellite Images", AOGS, August 2022</p>
                        <p>Namiko Toriyama、Hiroki Takano、Shotaro Watanabe "Proposal of an Automatic Labeling Method for Typhoon Path Maps", The Meteorological Society of Japan Autumn Meeting, Dec 2021</p>
                        <p>Namiko Toriyama、Mitsuo Yoshida、Takayuki Itoh "Visualization of network by iterative graph sampling from large scale networks", IEEE IV, July 2021</p>
                        <p>Namiko Toriyama、Tsubasa Kohyama、Hiroaki Miura "Analysis of Typhoon-Eye Diameters in Satellite Images", The Meteorological Society of Japan Spring Meeting, May 2021</p>
                        <p>Namiko Toriyama、Mitsuo Yoshida、Takayuki Itoh "Visualization of network by iterative graph sampling from large scale networks", DEIM, March 2021</p>
                        <p>Namiko Toriyama、Tsubasa Kohyama、Hiroaki Miura "Analysis of Typhoon-Eye Diameters in Satellite Images", The Meteorological Society of Japan Spring Meeting, April 2020</p>
                        <br></br>

                        <h2>Paper</h2>
                        <p>Visualization of sub-network sets by iterative graph sampling from large scale networks  [<a href="https://ieeexplore.ieee.org/abstract/document/9582686">link</a>]</p>
                        <br></br>
                        
                        <h2> Graduation Thesis</h2>
                        <p>Visualization of network by iterative graph sampling from large scale networks(反復的なグラフサンプリングによる巨大ネットワークの局所可視化)  <a href="http://is.ocha.ac.jp/~siio/pdf/grad/2020/2020grad89.pdf">[pdf]</a></p>
                        <p>Analysis of Typhoon-Eye Diameters in Satellite Images(台風の眼のサイズに関する衛星画像の解析) <a href="http://is.ocha.ac.jp/~siio/pdf/grad/2020/2020grad37.pdf">[pdf]</a></p>
                        <br></br>

                        <h2>Award</h2>
                        <p>2021.9 AWS Women's Well-Architected hackason: First place at student group tournament [<a href="https://www.youtube.com/watch?v=tgy_PDKvEog">Youtube</a>]</p>
                        <p>March 2021 DEIM2021 Student Presentation Award [<a href="https://db-event.jpn.org/deim2021/post/awards.html">link</a>]</p>
                        <p>September 2020 Mercari HackWeek: Silver Award(@tori-chan) [<a href="https://mercan.mercari.com/articles/25142/">link</a>]</p>
                        <p>June 2020 Pre-Graduation Research Excellence Award "Analysis of Typhoon-Eye Diameters in Satellite Images"</p>
                        <br></br>

                        <h2>Media</h2>
                        <p><a href="https://mercan.mercari.com/en/articles/39373/">MerCafé vol.2─Merpay’s CPO Talks Shop with New Grads</a></p>
                        <p><a href="https://engineering.mercari.com/blog/entry/20230512-127cd1f253/">メルカリの新卒エンジニアはどう過ごす？2023年新卒研修の全貌を公開！</a></p>
                        <p><a href="https://biz.acaric.jp/webinar/2679">Webiner「Acaric HR Conference 2022/02/24」 Moderator</a></p>
                        <p><a href="https://www.weathermap.co.jp/2021/12/28/metsoc-mtg2021a/">The Meteorological Society of Japan Autumn Meeting 2021, Five people involved in the research and development of the weather map gave presentations.</a></p>
                        <p><a href="https://biz.acaric.jp/webinar/2578">Webiner「Acaric HR Conference 2022/01/27」 Moderator</a></p>
                        <p><a href="https://aws.amazon.com/jp/builders-flash/202111/gluoncv-lambda-for-telework/?awsf.filter-name=*all">AWS builders.flash「正しい姿勢でテレワークを健康的に ! GluonCV × AWS Lambda で姿勢をチェックしてみよう」</a></p>
                        <p><a href="https://mercan.mercari.com/articles/26923/">Build@Mercari/</a></p>
                        <p><a href="https://hacku.yahoo.co.jp/hacku2020online2/">HackU2020</a></p>
                        <p><a href="https://www.atmarkit.co.jp/ait/articles/2009/01/news014.html">15th Crisis Management Contest interview</a></p>
                        <p><a href="https://www.ibm.com/jp-ja/it-infrastructure/z/learn/master-the-mainframe-past-championship">IBM Master Mainframe</a></p>

                    </div>
                </div>
            </Grid>
        );
    }
}

export default AboutEn;
