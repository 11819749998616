import React from 'react';
import '../css/App.css';


class AboutFooter extends React.Component {
  render() {
    return (
      <div class="footer">
        Last Updated: Sep 2023 <br></br>
        © 2021 NamikoToriyama.
        
      </div>
    );
  }
}

export default AboutFooter;
