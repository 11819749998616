import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import Home from './Home';
import About from './cmp/About';
import AboutEn from './cmp/AboutEn';

class AppRouter extends Component {
  render() {
    return (
      <div>
        <Router>
            <React.Fragment>
            </React.Fragment>
            <Route  path='/About' component={About}/>
            <Route  path='/AboutEn' component={AboutEn}/>
            <Route exact path='/' component={Home}/>
        </Router>
      </div>
    );
  }
}

export default AppRouter;