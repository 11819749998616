import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AboutFooter from './cmp/Footer';
import Sns from './cmp/Sns';
import './css/App.css';

class Home extends Component {
  render() {
    return (
      <Grid
          container
          direction="column"
            justify="center">
        <div className="App">
          <Sns />
        </div>
        <div class="home-about">
        <Link to="/AboutEn">About⇢</Link>
        </div>
        <AboutFooter />
      </Grid>
      
    );
  }
}

export default Home;
