import React from 'react';
import { Link } from 'react-router-dom';

import '../css/App.css';

export default class Sns extends React.Component {
  render() {
    return (   
  
        <div className=""> 
          <div className="namiko">
          <Link to="/"><h1>Namiko Toriyama</h1></Link>
          </div>

          <div class="sns_box">
          {/* icon : https://fontawesome.com/ */}
            <div class="sns_button twitter">
              <a href="https://twitter.com/lnamikol" title="Tweet" target="_blank" rel="noopener noreferrer"><i class="fa fa-twitter"></i></a>
            </div>
            <div class="sns_button github">
              <a href="https://github.com/NamikoToriyama/" title="GitHub" target="_blank" rel="noopener noreferrer"><i class="fa fa-github"></i></a>
            </div>
            <div class="sns_button google">
              <a href="https://qiita.com/Tori-chan" title="Qiita" target="_blank" rel="noopener noreferrer"><i class="fa fa-search fa-stack-20x  fa-5x"></i></a>
            </div>
            <div class="sns_button linkedin">
              <a href="https://www.linkedin.com/in/namiko-toriyama-4223921b4" title="Linkedin" target="_blank" rel="noopener noreferrer"><i class="fa fa-linkedin fa-stack-20x  fa-5x"></i></a>
            </div>
            <div class="sns_button note">
              <a href="https://note.com/lnamikol" title="Blog"><i class="fa fa-file fa-stack-20x  fa-5x"></i></a>
            </div>
          </div>
        </div>

   )}
}

