import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import '../css/App.css';
import Sns from './Sns';


class About extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="column"
        justify="center">
           <div className="App">
          <Sns />
        </div>
        <div class="box">
          <div class="contents">
            <div class="lang">
              Language: <Link to="/AboutEn">[En]</Link>
            </div>
            <br />

            <h2>Profile</h2>
            <p>鳥山 菜海子 (Toriyama Namiko)</p>
            <p>E-mail: namiko.trym@gmail.com</p>

            <h2>Education</h2>
            <p>2023.4-現在 </p>
            <p class="indent">お茶の水女子大学 人間文化創成科学研究科 理学専攻 情報科学コース (博士課程)</p>
            <p>2021.4-2023.3 </p>
            <p class="indent">お茶の水女子大学 人間文化創成科学研究科 理学専攻 情報科学コース (修士課程)</p>
            <p>2019.2-2019.3 </p>
            <p class="indent">University of Hull English Language Short Courses</p>
            <p>2017.4-2021.3 </p>
            <p class="indent">お茶の水女子大学 理学部 情報科学科</p>

            <h2>Work Experiences</h2>
            <p>2023.4-現在 株式会社メルカリ / バックエンドエンジニア </p>
            <p>2020.3-現在 ウェザーマップ株式会社 / リサーチエンジニア </p>
            <p>2021.10-2022.03 株式会社メルカリ / バックエンドエンジニア </p>
            <p>2021.7-2021.9 Amazon Web Services / ソリューションアーキテクト・プロトタイプチーム </p>
            <p>2020.8-2020.11 株式会社メルカリ / Build@Mercariインターンシップ・バックエンドエンジニア </p>
            <p>2018.7-2018.8 Google合同株式会社 / GoogleSTEPインターンシップ・Chromeチーム </p>
            <p>2017.7-2020.10 吉積情報株式会社 / エンジニア </p>

            <h2>Other Activity</h2>
            <p>TEAra 代表: <a href="https://note.com/lnamikol/n/na4da29fb373d">https://note.com/lnamikol/n/na4da29fb373d</a></p>

            <h2>Presentation</h2>
            <p>2022.12 鳥山菜海子、神山翼、三浦裕亮 「Spatio-Temporal Analysis of Typhoon Eye Diameters」AGU2022 </p>
            <p>2022.8 鳥山菜海子、神山翼、三浦裕亮「Analysis of Typhoon-Eye Diameters in Satellite Images」AOGS2022</p>
            <p>2021.12 鳥山菜海子, 高野 雄紀, 渡辺 正太郎 「台風進路図への自動ラベル配置手法の提案」気象学会2021年度秋季大会 </p>
            <p>2021.7 鳥山菜海子、吉田光男、伊藤貴之「反復的なグラフサンプリングによる巨大ネットワークの局所可視化」IEEE IV2021</p>
            <p>2021.4 鳥山菜海子、神山翼、三浦裕亮 「台風の目の直径に関する衛星画像の解析」  気象学会2021年度春季大会 </p>
            <p>2021.3 鳥山菜海子、吉田光男、伊藤貴之「反復的なグラフサンプリングによる巨大ネットワークの局所可視化」DEIM2021</p>
            <p>2020.4 鳥山菜海子、神山翼、三浦裕亮 「台風の目の直径に関する衛星画像の解析」  気象学会2020年度春季大会 </p>
            <br></br>

            <h2>Paper</h2>
            <p>Visualization of sub-network sets by iterative graph sampling from large scale networks  [<a href="https://ieeexplore.ieee.org/abstract/document/9582686">link</a>]</p>
            <br></br>

            <h2> Graduation Thesis</h2>
            <p>鳥山 菜海子 (指導教員：伊藤貴之) 反復的なグラフサンプリングによる巨大ネットワークの局所可視化  <a href="http://is.ocha.ac.jp/~siio/pdf/grad/2020/2020grad89.pdf">[pdf]</a></p>
            <p>鳥山 菜海子 (指導教員：神山 翼) 台風の眼のサイズに関する衛星画像の解析 <a href="http://is.ocha.ac.jp/~siio/pdf/grad/2020/2020grad37.pdf">[pdf]</a></p>
            <br></br>
            
            <h2>Award</h2>
            <p>2021.9 AWS Women's Well-Architected ハッカソン 学生部門優勝 [<a href="https://www.youtube.com/watch?v=tgy_PDKvEog">Youtube</a>]</p>
            <p>2021.3 DEIM2021 学生プレゼンテーション賞 [<a href="https://db-event.jpn.org/deim2021/post/awards.html">link</a>]</p>
            <p>2020.9 Mercari HackWeek シルバーアワード(@tori-chan) [<a href="https://mercan.mercari.com/articles/25142/">link</a>]</p>
            <p>2020.6 プレ卒業研究優秀研究賞「台風の目の直径に関する衛星画像の解析」</p>
            <br></br>

            <h2>Media</h2>
            <p><a href="https://mercan.mercari.com/articles/39373/">MerCafé vol.2 ──メルペイCPOと新卒メンバーで、プロダクト談義してみました</a></p>
            <p><a href="https://engineering.mercari.com/blog/entry/20230512-127cd1f253/">メルカリの新卒エンジニアはどう過ごす？2023年新卒研修の全貌を公開！</a></p>
            <p><a href="https://biz.acaric.jp/webinar/2679">セミナー「Acaric HR Conference カゴメHRBPの事例から考える戦略人事の最先端」 モデレータ</a></p>
            <p><a href="https://www.weathermap.co.jp/2021/12/28/metsoc-mtg2021a/">日本気象学会2021年度秋季大会 ウェザーマップの研究開発に携わる5名が発表</a></p>
            <p><a href="https://biz.acaric.jp/webinar/2578">セミナー「Acaric HR Conference DX人材をグロースさせる新人材戦略とは？」 モデレータ</a></p>
            <p><a href="https://aws.amazon.com/jp/builders-flash/202111/gluoncv-lambda-for-telework/?awsf.filter-name=*all">AWS builders.flash「正しい姿勢でテレワークを健康的に ! GluonCV × AWS Lambda で姿勢をチェックしてみよう」</a></p>
            <p><a href="https://mercan.mercari.com/articles/26923/">Build@Mercari</a></p>
            <p><a href="https://hacku.yahoo.co.jp/hacku2020online2/">HackU2020</a></p>
            <p><a href="https://www.atmarkit.co.jp/ait/articles/2009/01/news014.html">第15回情報危機管理コンテスト インタビュー</a></p>
            <p><a href="https://www.ibm.com/jp-ja/it-infrastructure/z/learn/master-the-mainframe-past-championship">IBM Master Mainframe</a></p>

          </div>
        </div>
      </Grid>
    );
  }
}

export default About;
